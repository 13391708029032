<template>
  <!-- begin:: Footer -->
  <div
    class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover"
    id="kt_footer"
    style="background-color: #45154D;"
  >
    <!--begin::Container-->
    <div class="container py-lg-18 py-8">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-sm">
          <h4 class="text-white py-3">{{ $t('companis_') }}</h4>
          <div class="d-flex flex-column">
            <span @click="$router.push('/about').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('about_us') }}
            </span>
            <span @click="$router.push('/contact').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('contact') }}
            </span>
            <span @click="$router.push('/jobs_list').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('jobs') }}
            </span>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-sm">
          <h4 class="text-white py-3">{{ $t('programs_') }}</h4>
          <div class="d-flex flex-column">
            <span @click="$router.push('/sponsorship').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('sponsorship') }}
            </span>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-sm">
          <h4 class="text-white py-3">{{ $t('support_') }}</h4>
          <div class="d-flex flex-column">
            <span @click="$router.push('/help_center').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('help_center') }}
            </span>
            <span @click="$router.push('/community_guidelines').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('community_guidelines') }}
            </span>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-sm">
          <h4 class="text-white py-3">{{ $t('legal_') }}</h4>
          <div class="d-flex flex-column">
            <span @click="$router.push('/cookie_policy').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('cookie_policy') }}
            </span>
            <span @click="$router.push('/business_agreement').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('business_user_agreement') }}
            </span>
            <span @click="$router.push('/imprint').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('imprint') }}
            </span>
            <span @click="$router.push('/terms').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('terms_service') }}
            </span>
            <span @click="$router.push('/privacy').catch(()=>{})" class="py-2 pointer text-white opacity-55 text-hover-primary">
              {{ $t('privacy_policy') }}
            </span>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="d-flex vertical-center">
      <div class="col-sm separator separator-solid opacity-7"></div>
      <img :src="logoNB" class="pointer mx-10 max-h-35px" @click="$router.push('/').catch(()=>{})" />
      <div class="col-sm separator separator-solid opacity-7" />
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div class="d-flex align-items-center justify-content-between flex-lg-row flex-column">
        <span style="width: 130px;">&nbsp;</span>
        <!--begin::Copyright-->
        <div class="d-flex center order-lg-1 order-2">
          <span class="text-muted font-weight-bold mx-2">&copy; {{ new Date().getFullYear() }} Eventbox All rights reserved.</span>
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <a
            href="https://www.youtube.com/watch?v=lfFzx5zCsS4"
            target="_blank"
            class="text-white opacity-55 pl-0 text-hover-primary"
          >
            <img :src="youtube" :width="30" :height="30" />
          </a>
          <a
            href="https://www.facebook.com/Eventbox-NB-112245895129691"
            target="_blank"
            class="text-white opacity-55 px-6 text-hover-primary"
          >
            <img :src="facebook" :width="30" :height="30" />
          </a>
          <a
            href="https://www.instagram.com/eventbox.germany?igsh=bHNlb2JpdnR4azg5&utm_source=qr"
            target="_blank"
            class="text-white opacity-55 pr-0 text-hover-primary"
          >
            <img :src="instagram" :width="30" :height="30" />
          </a>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
    <VueWhatsappWidget
      companyName="eventbox"
      :companyLogo="logoNB"
      phoneNumber="+4915225602793"
    />
  </div>
  <!-- end:: Footer -->
</template>

<style>
  #whatsapp-chat {
    background-color: white !important;
  }
  .whatsapp-chat-name-block {
    padding-top: 10px !important;
  }
  .whatsapp-chat-name-block * {
    color: white !important;
  }
</style>

<script>
import VueWhatsappWidget from 'vue-whatsapp-widget';

import logoLight from '@/assets/logo/logo_light.png';
import logoNB from '@/assets/logo/logo_nb.png';
import facebook from '@/assets/footer/facebook.png';
import instagram from '@/assets/footer/instagram.png';
import youtube from '@/assets/footer/youtube.png';

export default {
  name: 'KTFooter',
  components: {
    VueWhatsappWidget
  },
  data() {
    return {
      logoLight,
      logoNB,
      facebook,
      instagram,
      youtube
    }
  }
};
</script>
